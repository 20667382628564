import { useState, useEffect, useRef } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "../components/ui/button"
import { CountdownDigit } from "../components/ui/countdown-digit"
import { Sparkles } from "../components/ui/sparkles"
import BlockchainBackground from "../components/BlockchainBackground"
import { Card, CardHeader, CardTitle, CardDescription } from "../components/ui/card"
import { ArrowUpRight } from 'lucide-react'
import { features, firstBatchTransactions, secondBatchTransactions } from "../data/homeData"
import { TransactionFeed } from "../components/TransactionFeed";
import { MobileMenu } from "../components/ui/mobile-menu"

export default function Home() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
  const featuresRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const endTime = new Date('2024-12-25T00:00:00Z');
    const difference = +endTime - +now;

    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  const titleChars = "Chainpump.fun".split("")

  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#5D3891] to-[#322653] text-white flex flex-col relative overflow-x-hidden">
      <BlockchainBackground />
      <Sparkles />

      <header className="relative p-4 flex justify-center items-center border-b border-[#ffffff10] z-50">
        <div className="container max-w-6xl flex justify-between items-center">
          <motion.div
            className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-transparent bg-clip-text"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            Chainpump.fun
          </motion.div>
          <div className="hidden sm:flex items-center gap-4">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Button
                className="bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-white opacity-50 cursor-not-allowed"
                disabled
              >
                Whitepaper
              </Button>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <Button
                className="bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-white hover:opacity-90"
                onClick={scrollToFeatures}
              >
                Features
              </Button>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Button
                className="bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-white opacity-50 cursor-not-allowed"
                disabled
              >
                <span className="flex items-center">
                  APP
                  <ArrowUpRight className="ml-1 w-4 h-4" />
                </span>
              </Button>
            </motion.div>
          </div>
          <MobileMenu onFeatureClick={scrollToFeatures} />
        </div>
      </header>

      <div className="w-full px-4 py-2">
        <div className="max-w-6xl mx-auto flex flex-col sm:flex-row justify-between items-center gap-2">
          <TransactionFeed transactions={firstBatchTransactions} />
          <TransactionFeed transactions={secondBatchTransactions} />
        </div>
      </div>

      <main className="flex-1 flex flex-col items-center justify-center px-4 py-8 sm:py-16 relative z-10">
        <div className="text-center max-w-4xl mx-auto">
          <motion.h1
            className="text-4xl sm:text-6xl md:text-8xl font-bold mb-4 flex justify-center flex-wrap"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <AnimatePresence>
              {titleChars.map((char, i) => (
                <motion.span
                  key={i}
                  className="inline-block bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-transparent bg-clip-text"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    rotate: [0, -10, 10, 0],
                    scale: [1, 1.2, 1]
                  }}
                  transition={{
                    duration: 0.5,
                    delay: i * 0.1,
                    rotate: {
                      duration: 0.5,
                      delay: i * 0.1 + 0.5,
                      ease: "easeInOut"
                    }
                  }}
                  whileHover={{
                    scale: 1.2,
                    rotate: [0, -5, 5, 0],
                    transition: { duration: 0.3 }
                  }}
                >
                  {char}
                </motion.span>
              ))}
            </AnimatePresence>
          </motion.h1>

          <motion.p
            className="text-lg sm:text-xl md:text-2xl mb-8 sm:mb-16 text-gray-300"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            fun for all chain, easy deployment of bonding curve
          </motion.p>

          <motion.div
            className="flex flex-wrap justify-center gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.7 }}
          >
            <CountdownDigit value={timeLeft.days} label="DAYS" />
            <CountdownDigit value={timeLeft.hours} label="HOURS" />
            <CountdownDigit value={timeLeft.minutes} label="MINUTES" />
            <CountdownDigit value={timeLeft.seconds} label="SECONDS" />
          </motion.div>

          <motion.div
            className="mt-8 sm:mt-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 2 }}
          >
            <Button
              className="text-lg sm:text-xl py-4 sm:py-6 px-8 sm:px-12 bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-white hover:opacity-90 transition-all duration-300 transform hover:scale-105"
            >
              Join Now
            </Button>
          </motion.div>
        </div>
      </main>

      <section ref={featuresRef} className="py-12 sm:py-20 px-4 relative z-10">
        <div className="container mx-auto max-w-7xl">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 sm:mb-12 text-center bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-transparent bg-clip-text">
            Our Features
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Card className="bg-gradient-to-br from-[#5D3891] to-[#322653] border-[#ffffff20] h-full transition-all duration-300 hover:shadow-lg hover:shadow-[#FF69B440] group">
                  <CardHeader>
                    <div className="mb-4 p-2 bg-gradient-to-br from-[#FF69B440] to-[#9370DB40] rounded-full w-12 sm:w-16 h-12 sm:h-16 flex items-center justify-center group-hover:from-[#FF69B4] group-hover:to-[#9370DB] transition-all duration-300">
                      <feature.icon className="w-6 h-6 sm:w-8 sm:h-8 text-white" />
                    </div>
                    <CardTitle className="text-xl sm:text-2xl font-bold text-white group-hover:bg-gradient-to-r group-hover:from-[#FF69B4] group-hover:to-[#9370DB] group-hover:text-transparent group-hover:bg-clip-text transition-all duration-300">{feature.title}</CardTitle>
                    <CardDescription className="text-sm sm:text-base text-gray-300 group-hover:text-white transition-all duration-300">
                      {feature.description}
                    </CardDescription>
                  </CardHeader>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
