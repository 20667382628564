import React from "react";
import { motion } from "framer-motion";

interface CountdownDigitProps {
  value: number | undefined;
  label: string;
}

export const CountdownDigit: React.FC<CountdownDigitProps> = ({ value, label }) => {
  return (
    <motion.div
      className="flex flex-col items-center"
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5, type: "spring" }}
    >
      <motion.div
        className="relative w-16 h-20 md:w-24 md:h-32 bg-gradient-to-br from-[#322653] to-[#5D3891] rounded-xl flex items-center justify-center shadow-lg"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.2 }}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-[#FF69B420] to-[#9370DB20] rounded-xl" />
        <span className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-transparent bg-clip-text">
          {value !== undefined ? value.toString().padStart(2, '0') : '00'}
        </span>
      </motion.div>
      <span className="mt-2 text-xs md:text-sm text-gray-400 font-medium">{label}</span>
    </motion.div>
  );
};

