import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface Transaction {
  type: 'BUY' | 'SELL';
  amount: string;
  token: string;
}

interface TransactionFeedProps {
  transactions: readonly Transaction[];
}

export function TransactionFeed({ transactions }: TransactionFeedProps) {
  const [currentTransaction, setCurrentTransaction] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTransaction((prev) => (prev + 1) % transactions.length);
    }, 3000); // Change transaction every 3 seconds

    return () => clearInterval(interval);
  }, [transactions]);

  const containerVariants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const emojiVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: [0.5, 1, 0.5],
      scale: [0.8, 1.2, 0.8],
      transition: {
        duration: 0.3,
        repeat: Infinity,
        ease: "easeInOut",
      }
    },
  };

  return (
    <div className="w-full sm:w-[48%] max-w-xs p-1 overflow-hidden h-10">
      <div className="relative w-full h-full">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentTransaction}
            variants={containerVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              y: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 }
            }}
            className={`absolute w-full p-1.5 rounded-md text-xs font-medium ${
              transactions[currentTransaction].type === 'BUY'
                ? 'bg-green-500 text-white'
                : 'bg-red-500 text-white'
            }`}
          >
            <div className="flex items-center justify-center">
              <motion.span
                className="mr-2 text-xl"
                variants={emojiVariants}
                initial="hidden"
                animate="visible"
              >
                {transactions[currentTransaction].type === 'BUY' ? '🚀' : '💥'}
              </motion.span>
              <motion.span
                animate={{
                  x: [0, -1, 1, -1, 1, 0],
                  y: [0, 1, -1, 1, -1, 0],
                }}
                transition={{
                  duration: 0.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="flex items-center"
              >
                {transactions[currentTransaction].type}{' '}
                {transactions[currentTransaction].amount} for{' '}
                {transactions[currentTransaction].token}
              </motion.span>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

