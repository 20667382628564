import { Brain, GitBranch, ShieldCheck, Gamepad2, BarChart3, Lightbulb, Link, Users, Megaphone, Lock, ArrowUpRight, DollarSign, Rocket } from 'lucide-react';

export const features = [
  { icon: DollarSign, title: "Revenue Sharing Model", description: "Allocates 30% of platform-generated revenue to token stakers and holders, with transparent reporting at mainnet launch for accountability and trust." },
  { icon: GitBranch, title: "Seamless Multi-Chain Interoperability", description: "Ensures compatibility across all EVM-compatible blockchains, allowing users to operate across multiple platforms without friction." },
  { icon: Gamepad2, title: "Gamified Staking Mechanisms", description: "Introduces game-like elements into staking, increasing user engagement and offering additional incentives for participation." },
  { icon: BarChart3, title: "Advanced Trading Tools", description: "Provides sophisticated tools for traders, including real-time analytics, automated strategies, and customizable interfaces to enhance trading efficiency." },
  { icon: Lightbulb, title: "AI-Enhanced Insights", description: "Delivers actionable insights powered by artificial intelligence, aiding users in making informed investment and trading decisions." },
  { icon: Link, title: "Integration with DeFi Ecosystem", description: "Facilitates seamless interaction with various DeFi protocols, expanding the platform's utility and user reach." },
  { icon: Users, title: "Social and Community Features", description: "Incorporates social elements to foster community engagement, including forums, leaderboards, and collaborative opportunities." },
  { icon: Lock, title: "Advanced Security Measures", description: "Implements robust security protocols to protect user assets and data, ensuring a safe operating environment." },
  { icon: Rocket, title: "Meme-Powered Growth", description: "Harnesses the viral nature of memes to drive community engagement, brand awareness, and exponential growth." },
];

type Transaction = {
  type: 'BUY' | 'SELL';
  amount: string;
  token: string;
};

export const firstBatchTransactions: readonly Transaction[] = [
  { type: 'BUY', amount: '0.5 SOL', token: 'PUMP' },
  { type: 'BUY', amount: '1.2 SOL', token: 'PUMP' },
  { type: 'BUY', amount: '0.3 SOL', token: 'PUMP' },
  { type: 'SELL', amount: '1000 PUMP', token: 'SOL' },
  { type: 'BUY', amount: '0.8 SOL', token: 'PUMP' },
  { type: 'BUY', amount: '2.0 SOL', token: 'PUMP' },
  { type: 'SELL', amount: '500 PUMP', token: 'SOL' },
  { type: 'BUY', amount: '0.6 SOL', token: 'PUMP' },
  { type: 'BUY', amount: '1.5 SOL', token: 'PUMP' },
];

export const secondBatchTransactions: readonly Transaction[] = [
  { type: 'BUY', amount: '1.8 SOL', token: 'PUMP' },
  { type: 'SELL', amount: '800 PUMP', token: 'SOL' },
  { type: 'BUY', amount: '0.7 SOL', token: 'PUMP' },
  { type: 'BUY', amount: '2.5 SOL', token: 'PUMP' },
  { type: 'SELL', amount: '300 PUMP', token: 'SOL' },
  { type: 'BUY', amount: '1.0 SOL', token: 'PUMP' },
  { type: 'BUY', amount: '3.2 SOL', token: 'PUMP' },
  { type: 'SELL', amount: '1500 PUMP', token: 'SOL' },
  { type: 'BUY', amount: '0.9 SOL', token: 'PUMP' },
];