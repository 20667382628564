import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({ className = '', children, disabled, ...props }) => {
  return (
    <button
      className={`px-4 py-2 rounded-md transition-all duration-300 ${
        disabled
          ? 'opacity-75 cursor-not-allowed'
          : 'hover:opacity-90 hover:shadow-md'
      } ${className}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;

