import { useState } from 'react'
import Button from './button'
import { Menu, X } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

interface MobileMenuProps {
  onFeatureClick: () => void
}

export function MobileMenu({ onFeatureClick }: MobileMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(prev => !prev);

  return (
    <div className="sm:hidden">
      <Button
        onClick={toggleMenu}
        className="p-2 bg-transparent hover:bg-[#FF69B440] text-white"
      >
        {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
      </Button>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="absolute top-full left-0 right-0 bg-[#322653] shadow-lg p-4 space-y-2 z-50"
            >
              <Button
                className="w-full bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-white opacity-50 cursor-not-allowed"
                disabled
              >
                Whitepaper
              </Button>
              <Button
                className="w-full bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-white hover:opacity-90"
                onClick={() => {
                  onFeatureClick();
                  setIsOpen(false);
                }}
              >
                Features
              </Button>
              <Button
                className="w-full bg-gradient-to-r from-[#FF69B4] to-[#9370DB] text-white opacity-50 cursor-not-allowed"
                disabled
              >
                <span className="flex items-center justify-center">
                  APP
                  <span className="ml-1">↗</span>
                </span>
              </Button>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={toggleMenu}
            />
          </>
        )}
      </AnimatePresence>
    </div>
  )
}