import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const CanvasComponent = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Create grid blocks
    const gridBlocks: {
      x: number;
      y: number;
      size: number;
      speed: number;
      glowIntensity: number;
      pulseDirection: number;
    }[] = [];

    // Initialize grid blocks
    for (let i = 0; i < 30; i++) {
      gridBlocks.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 60 + 40, // Larger blocks
        speed: Math.random() * 0.2 + 0.1, // Slightly faster
        glowIntensity: Math.random() * 0.4 + 0.1,
        pulseDirection: 1
      });
    }

    const drawBlock = (
      x: number,
      y: number,
      size: number,
      glowIntensity: number
    ) => {
      if (!ctx) return;

      // Create glowing effect
      const gradient = ctx.createRadialGradient(
        x + size / 2,
        y + size / 2,
        0,
        x + size / 2,
        y + size / 2,
        size
      );

      // Enhanced purple glow
      gradient.addColorStop(0, `rgba(93, 56, 145, ${glowIntensity * 0.5})`);
      gradient.addColorStop(0.5, `rgba(93, 56, 145, ${glowIntensity * 0.3})`);
      gradient.addColorStop(1, 'rgba(50, 38, 83, 0)');

      ctx.fillStyle = gradient;
      ctx.strokeStyle = `rgba(255, 255, 255, ${glowIntensity * 0.2})`;
      ctx.lineWidth = 2;

      // Draw main block
      ctx.beginPath();
      ctx.rect(x, y, size, size);
      ctx.fill();
      ctx.stroke();

      // Draw grid lines
      ctx.beginPath();
      ctx.strokeStyle = `rgba(255, 255, 255, ${glowIntensity * 0.1})`;
      ctx.moveTo(x, y + size / 2);
      ctx.lineTo(x + size, y + size / 2);
      ctx.moveTo(x + size / 2, y);
      ctx.lineTo(x + size / 2, y + size);
      ctx.stroke();

      // Add corner accents
      const cornerSize = size * 0.2;
      ctx.strokeStyle = `rgba(255, 105, 180, ${glowIntensity * 0.3})`;

      // Top-left corner
      ctx.beginPath();
      ctx.moveTo(x, y + cornerSize);
      ctx.lineTo(x, y);
      ctx.lineTo(x + cornerSize, y);
      ctx.stroke();

      // Bottom-right corner
      ctx.beginPath();
      ctx.moveTo(x + size - cornerSize, y + size);
      ctx.lineTo(x + size, y + size);
      ctx.lineTo(x + size, y + size - cornerSize);
      ctx.stroke();
    };

    const animate = () => {
      if (!ctx || !canvas) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      gridBlocks.forEach(block => {
        // Update block position
        block.y += block.speed;
        if (block.y > canvas.height) {
          block.y = -block.size;
          block.x = Math.random() * canvas.width;
        }

        // Pulse the glow effect
        block.glowIntensity += 0.01 * block.pulseDirection;
        if (block.glowIntensity > 0.5) {
          block.pulseDirection = -1;
        } else if (block.glowIntensity < 0.1) {
          block.pulseDirection = 1;
        }

        drawBlock(block.x, block.y, block.size, block.glowIntensity);
      });

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      if (!canvas) return;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <motion.canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none bg-[#1a1a1a]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    />
  );
};

export default CanvasComponent;

